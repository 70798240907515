<template>
  <div class="mt-4" v-if="showDetail">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">Update Task</h1>
        <div class="flex items-center gap-3">
          </div>
      </div>
    </div>
    <div class="flex justify-center" >
      <div class="sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
        <div class=" card rounded-xl bg-white p-4 my-4 ">
          <div class="mb-4 ">
            <TextField
              :inputId="'jobtitlename'"
              :textMaxlength="100"
              :showcharLimit="true"
              :inputext="taskObject.taskTitle"
              :fieldError="errObj.titleErr !== ''"
              :placholderText="`Title`"
              :lableText="'Title'"
              :autoFocus="false"
              @keyPressAction="errObj.titleErr = ''"
              @inputChangeAction="(data) => taskObject.taskTitle = data"  />

              <p class="text-error heading-7">{{errObj.titleErr}}</p>
          </div>
          <div class=" col-span-2 mb-4">
            <div class="heading-5" v-if="!addTaskDesc">
              <span @click="addTaskDesc = true" class="text-primary font-bold cursor-pointer">Add Description</span>
            </div>
            <TextAreaInput v-if="addTaskDesc"
              :inputId="'discriptioncon'"
              :inputext="taskObject.taskDescription"
              :placholderText="`Write description here...`"
              :lableText="'Description'"
              :inputType="'text'"
              :autoFocus="false"
              :textMaxlength="1000"
              :showcharLimit="true"
              :cols="50"
              :rows="3"
              :setReadOnly="false"
              @inputChangeAction="(data) => taskObject.taskDescription = data"  />
          </div>
        </div>
        <div class="flex card rounded-xl bg-white p-4 my-4">
          <SheduleVisit :taskDetail="taskDetail"/>
        </div>
        <div class="flex justify-end card rounded-xl bg-white p-4 my-4">
          <div class="text-rigth flex gap-2">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="$router.go(-1)"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Update'" @buttonAction="update()"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="confPopup" class="popup_overlay relative px-4">
       <div style="width: 380px;" class="custom_dialog rounded-xl h-min p-4 text-center">
          <div class="mb-2">
            <p class="heading-3 text-text1">Select the task update option</p>
          </div>
          <div class="divider"></div>
          <div class="">
            <p class="heading-4 text-gray4 my-2 cursor-pointer hover:bg-gray-50" @click="updateTask(false)">Update only this task</p>
            <p class="heading-4 text-gray4 cursor-pointer hover:bg-gray-50" @click="updateTask(true)">Update this and all Future task</p>
          </div>
          <div  class="mb-5">
          </div>
          <div class="flex gap-2 justify-end">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="confPopup = false"/>
          </div>
      </div>
    </div>
   </div>
</template>
<script>
import moment from 'moment'
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import SheduleVisit from './updTaskScheduleComp.vue'
import TextField from '@/View/components/textfield.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  name: "customer",
  components: {
    Button,
    TextAreaInput,
    TextField,
    SheduleVisit,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      addTaskDesc: false,
      confPopup: false,
      showDetail: false,
      taskDetail: {},
      errObj: {
        custErr:'',
        titleErr:'',
        addressErr:''
      },
      scheduleDate: '',
      taskObject: {
        taskId: 0,
        taskTitle: '',
        taskDescription: '',
        oldTaskDate: '',
        oldStartTimeTime: '',
        oldEndTimeTime: '',
        taskDate: '',
        startTime: '',
        endTime: '',
        reminderBeforeMinutes: '',
        assignToList: [],
        isTimeErr: false,
      }
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  computed: {
  },
  watch: {
    taskObject: {
      handler () {
        console.log('taskObject------------------->>>>>>>>----------------->>>>>', this.taskObject)
      },
      deep: true
    }
  },
  mounted() {
    this.scheduleDate = this.$route.query.date
    this.getTaskDetail()
    this.taskObject.taskDate = moment(new Date()).format('YYYY-MM-DD')
    console.log("route", this.$route.query);
    this.$root.$on('setReminderVal', (response) => {
      console.log('response', response)
      this.taskObject.reminderBeforeMinutes = response.value
    })
    this.$root.$on('setSelectedEmpHandler', (response) => {
      this.taskObject.assignToList = response
    })
    this.$root.$on('scheduleTimeEmit', (response) => {
      console.log('response 11', response)
      this.taskObject.startTime = response.compStartTime
      this.taskObject.endTime = response.compEndTime
      this.taskObject.taskDate = moment(new Date(response.compStartTime)).format('YYYY-MM-DD')
      this.taskObject.isTimeErr = response.Error
    })
  },
  beforeDestroy() {
    this.$root.$off("setReminderVal");
    this.$root.$off("setSelectedEmpHandler");
    this.$root.$off("scheduleTimeEmit");
  },
  methods: {
    update () {
      console.log('this.taskObject---> SAVE---> ', this.taskObject)
      this.taskObject.assignToList = this.taskObject.assignToList === null ? [] : this.taskObject.assignToList
      if (this.taskObject.assignToList.length === 0) {
        this.taskObject.reminderBeforeMinutes = 0
      }
      let isValid = true
      if (this.taskObject.taskTitle.trim() === '') {
        this.errObj.titleErr = 'title is required'
        isValid = false
      }
      if (this.taskObject.isTimeErr) {
        isValid = false
      }
      if (this.taskObject.assignToList.length === 0) {
        this.$root.$emit('assigneeErr', true)
        isValid = false
      }
      if (isValid) {
        if (this.taskDetail.isRepeated) {
          this.confPopup = true
        } else {
          this.updateTask(false)
        }
      }
    },
    updateTask (value) {
      this.confPopup = false
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.UpdateTaskDetail(
        this.taskObject,
        value,
        (response) => {
            this.$router.push({name: 'TaskDetail', params: {taskId: response.Data}, query: {date: this.scheduleDate}})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      );
    },
    getTaskDetail () {
      MyJobApp.GetTaskDetail(
        parseInt(this.$route.params.taskId),
        moment(new Date(this.scheduleDate)).format('YYYY-MM-DD'),
        response => {
          this.taskDetail = response.Data !== null ? response.Data : null
          this.taskObject.taskTitle = response.Data.taskTitle
          this.taskObject.taskId = response.Data.taskId
          this.taskObject.taskDescription = response.Data.taskDescription
          this.taskObject.taskDescription !== '' ? this.addTaskDesc = true : this.addTaskDesc = false 
          this.taskObject.oldTaskDate = moment(new Date(response.Data.taskDate)).format('YYYY-MM-DD')
          this.taskObject.oldStartTimeTime = response.Data.startTime
          this.taskObject.oldEndTimeTime = response.Data.endTime
          this.taskObject.taskDate = moment(new Date(response.Data.taskDate)).format('YYYY-MM-DD')
          this.taskObject.startTime = response.Data.startTime
          this.taskObject.endTime = response.Data.endTime
          this.taskObject.reminderBeforeMinutes = response.Data.reminderBeforeMinutes
          this.taskObject.assignToList = response.Data.assignToList
          this.showDetail = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.showDetail = true
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158
    },
  },
};
</script>
<style scoped>

</style>